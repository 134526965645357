<template>
  <div class="home">
    {{ $t("nav.home") }}<br>
  </div>
</template>

<script setup>
import { useHead } from '@unhead/vue'
import { i18n } from 'vue-lang-router'

const locale = i18n.global.locale; // Die aktuelle Spracheinstellung holen
const page = 'home';
const metaTitle = i18n.global.messages[locale].meta[page].title; // Die Metabeschreibung für die aktuelle Spracheinstellung holen
const metaDescription = i18n.global.messages[locale].meta[page].description; // Die Metabeschreibung für die aktuelle Spracheinstellung holen

useHead({
  title: metaTitle,
  meta: [
    { name: 'description', content: metaDescription }
  ]
})
</script>