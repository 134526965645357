<template>
  <div class="w-layout-hflex header">
    <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease-in-out" data-easing2="ease" data-no-scroll="1" role="banner" class="navigation w-nav">
      <div class="sidestripe"></div>
      <div class="navigation-wrap">
        <div class="menu">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <localized-link to="/" class="navigation-item w-nav-link">{{ $t("nav.home") }}</localized-link>
            <localized-link to="/about/" class="navigation-item w-nav-link">{{ $t("nav.about") }}</localized-link>
            <a href="https://www.facebook.com/caldenhoven" class="navigation-item w-nav-link" target="_blank">Facebook</a>
            <localized-link to="/#to-contact" class="button cc-contact-us w-inline-block">
              <img src="@/assets/images/icon-menu-contact.svg" loading="lazy" width="20" alt="">
              <div class="text-block">Kontakt</div>
            </localized-link>
          </nav>
          <div class="menu-button w-nav-button"><img src="@/assets/images/icon-menu.svg" width="48" alt="" class="menu-icon"></div>
        </div>
        <a href="/" aria-current="page" class="logo-link w-nav-brand w--currentx"><img src="@/assets/images/caldenhoven-logo.svg" loading="lazy" alt="" class="logo-image"></a>
        <a href="/#to-contact" class="contact-link w-nav-brand"><img src="@/assets/images/icon-contact-xs.svg" loading="lazy" width="20" alt=""></a>
      </div>
      <div class="sidestripe stars"></div>
    </div>
    <language-switcher
      v-slot="{ links }"
    >
      <router-link
        v-for="link in links"
        :key="link.langIndex"
        :to="link.url"
        :class="link.activeClass"
        active-class=""
        exact-active-class="active"
      >
        <img :src="getCountryFlagURL(link.langIndex)" alt="" />
        <span>{{ link.langName }}</span>
      </router-link>
    </language-switcher>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  methods: {
    getCountryFlagURL(locale) {
      // Map locales to country codes to use with Country Flags API
      const map = {
        en: "us",
        de: "de",
        nl: "nl",
      };
      return `https://flagcdn.com/24x18/${map[locale]}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid var(--line);
  background-color: var(--light-gray);
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.w-nav {
  position: relative;
  background: #dddddd;
  z-index: 1000;
  
  &:before, &:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
  }
  
  &:after {
    clear: both;
  }
}

.logo-image {
  display: block;
}

.logo-link:hover {
  opacity: .8;
}

.logo-link:active {
  opacity: .7;
}

.navigation {
  width: 100%;
  max-width: 1232px;
  background-color: var(--light-gray);
  align-items: center;
  padding-left: 48px;
  padding-right: 48px;
  font-family: Inter, sans-serif;
  display: flex;
}

.navigation-wrap {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 48px;
  margin-right: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  position: relative;
}

.navigation-item {
  border-bottom: 2px solid var(--light-gray);
  color: var(--dark-blue);
  letter-spacing: .5px;
  text-transform: uppercase;
  padding: 6px 0 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: opacity .2s;
}

.navigation-item:hover {
  border-bottom: 2px solid var(--dark-blue);
}

.navigation-item:active {
  opacity: .8;
  color: var(--dark-blue);
  font-weight: 700;
}

.navigation-item.router-link-exact-active {
  border-bottom: 2px solid var(--red);
  color: var(--dark-blue);
  padding: 6px 0 4px;
  font-weight: 600;
}

.navigation-item.router-link-exact-active:hover {
  border-bottom-color: var(--dark-blue);
  opacity: .8;
  color: var(--dark-blue);
}

.navigation-item.router-link-exact-active:active {
  color: #32343a;
}

.navigation-items {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-nav-menu {
  position: relative;
  float: right;
}

.w-nav[data-collapse="all"] .w-nav-menu {
  display: none;
}

.navigation-items {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu {
  z-index: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.menu-button {
  padding: 0;
}

.menu-button.w--open {
  background-color: var(--line);
  margin-left: 0;
  transition: opacity .4s cubic-bezier(.455, .03, .515, .955);
}

.contact-link {
  background-color: var(--dark-blue);
  border-radius: 40px;
  margin-right: 8px;
  padding: 12px;
  display: none;
}

.button.cc-contact-us {
  z-index: 5;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--dark-blue);
  border-radius: 4px;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 24px;
  transition-duration: .25s, .4s, .4s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    background-color: var(--red);
  }
}

.button {
  background-color: var(--dark-blue);
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .4s, opacity .4s, color .4s;

  &:hover {
    background-color: var(--red);
    color: #fff;
  }

  &:active {
    background-color: #43464d;
  }
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}

.router-language-switcher {
  display: none;
  margin-bottom: 20px;

  a {
    margin: 0 5px;
  }
  img {
    vertical-align: middle;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1280px) {
  .navigation-wrap {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .header {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    z-index: 2;
  }

  .logo-image {
    height: 40px;
  }

  .logo-link {
    flex: 1;
    justify-content: center;
    display: flex;
  }

  .logo-link.w--currentx {
    flex: 1;
    justify-content: center;
    margin-right: 12px;
    display: flex;
  }

  img {
    display: block;
  }

  .navigation-wrap {
    background-color: var(--light-gray);
    margin-left: 12px;
    margin-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .navigation {
    padding-left: 0;
    padding-right: 0;
  }

  .navigation-item {
    border-bottom-style: none;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
  }

  .navigation-item:hover {
    border-bottom-style: none;
    border-bottom-color: var(--light-gray);
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation-item:active {
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation-item.router-link-exact-active {
    border-bottom-style: none;
    border-bottom-color: var(--light-gray);
    color: var(--red);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navigation-item.router-link-exact-active:hover {
    border-bottom-color: var(--light-gray);
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation-items {
    width: 100%;
    border-bottom: 1px solid var(--line);
    background-color: var(--white);
    margin-top: 1px;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    position: static;
  }

  .menu:focus {
    background-color: var(--red);
  }

  .menu-button {
    padding: 0;
  }

  .menu-button.w--open {
    background-color: var(--line);
    margin-left: 0;
    transition: opacity .4s cubic-bezier(.455, .03, .515, .955);
  }

  .button.cc-contact-us {
    display: none;
  }

  .contact-link {
    background-color: var(--dark-blue);
    border-radius: 40px;
    margin-right: 8px;
    padding: 12px;
    display: block;
  }

  .w-nav[data-collapse="medium"] .w-nav-menu {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .navigation-item {
    justify-content: flex-start;
  }

  .navigation-item:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .logo-link {
    padding-left: 0;
  }
}

@media screen and (max-width: 479px) {
  .menu-button.w--open {
    flex: none;
  }

  .navigation-items {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
</style>