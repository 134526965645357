import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from 'vue-lang-router'
import { createHead } from '@unhead/vue'
import "@/assets/css/normalize.css";
import "@/assets/css/style.css";

// Create a global head instance
const head = createHead()

export const defaultLocale = 'de'

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(head)
  .mount('#app')
