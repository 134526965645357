import { createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'
import { createLangRouter } from 'vue-lang-router'
import { i18n, defaultLocale } from 'vue-i18n'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:pathMatch(.*)*/',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundView.vue')
  }
]

const langRouterOptions = {
	defaultLanguage: 'de',
	translations,
	localizedURLs,
}

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}

const router = createLangRouter(langRouterOptions, routerOptions)

export default router

// Vor jedem Routenwechsel wird diese Funktion aufgerufen
router.beforeEach((to, from, next) => {
  // console.log(`Navigieren von ${from.path} nach ${to.path}`);
  // Wenn der Zielweg der Root-Pfad ist, leiten wir den Benutzer zur deutschen Startseite um
  if (to.path === '/') {
    next('/de/');
  } else {
    // In allen anderen Fällen lassen wir die Navigation unverändert
    next();
  }
})
