export default {
  de: {
    name: 'Deutsch',
		load: () => { return import('./de.json'); },
	},
  en: {
    name: 'English',
    load: () => { return import('./en.json'); },
  },
	nl: {
		name: 'Nederlands',
		load: () => { return import('./nl.json'); },
	},
};
